import { useCallback, useEffect, useRef } from "react";
import _throttle from "lodash/throttle";

export default function useThrottle(callback, delay) {
  const options = { leading: true, trailing: false };
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useCallback(
    _throttle((...args) => callbackRef.current(...args), delay, options),
    [delay]
  );
}
