import {
  getFiltersFromQueryStringParameters,
  getCurrentRoute,
} from "./urlHelpers";
import defaultGoTrackFilters from "./defaultGoTrackFilters";
import defaultSpotFilters from "./defaultSpotFilters";
import defaultUserManagementFilters from "./defaultUserManagementFilters";
import defaultCarrierManagementFilters from "./defaultCarrierManagementFilters";
import types from "./types";

export const getDefaultFilters = () => {
  const currentRoute = getCurrentRoute();
  const queryStringFilters = getFiltersFromQueryStringParameters();
  let defaultFilters;

  if (currentRoute === types.appRoutes.tracking) {
    defaultFilters = {
      filters: { ...defaultGoTrackFilters, ...queryStringFilters },
    };
  } else if (currentRoute === types.appRoutes.availableroutes) {
    defaultFilters = {
      spotFilters: { ...defaultSpotFilters, ...queryStringFilters },
    };
  } else if (currentRoute === types.appRoutes.userManagement) {
    defaultFilters = {
      userManagementFilters: {
        ...defaultUserManagementFilters,
        ...queryStringFilters,
      },
    };
  } else if (currentRoute === types.appRoutes.carrierManagement) {
    defaultFilters = {
      carrierManagementFilters: {
        ...defaultCarrierManagementFilters,
        ...queryStringFilters,
      },
    };
  }

  return defaultFilters;
};
