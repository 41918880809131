export const initializePendo = user => {
    window.pendo.initialize({
      visitor: {
        id: "RC-" + user.id
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
  
      account: {
        id: user.organizationName ?? "COY",       // Required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  };